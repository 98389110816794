/* APLICAR CSS NO COMPONENTE HEADER */

.header{
    width: 100%;
    position:fixed;
    top:0;
    left:0;
    z-index: var(--z-fixed);
    background-color: var(--body-color);
}

.nav{
    height: calc(var(--header-height) + 1.5rem);
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 1rem;
}

.logo{
    max-width: 200px;
    max-height: 120px;
}


.nav__logo,
.nav__toggle{
    color: var(--title-color);
    font-weight: var(--font-medium);
}

.nav__list{
    display: flex;
    column-gap: 2rem;
}

.nav__link{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: var(--small-font-s);
    color:var(--title-color);
    font-weight: var(--font-medium);
    transition: .3s;
}

.nav__icon,
.nav__close,
.nav__toggle{
    display: none;
}

/* Link apos click */
.nav__logo:hover,
.active-link,
.nav__link:hover{
    color: var(--button-icon-color);
}

/* mudar background menu */
.scroll-header{
    box-shadow: 0 -1px 4px rgba(0,0,0, 0.15);

}
/* BREAKPOINTS(MOBILE, TABLET, DESKTOP) */
  /* PARA DISPOSTIVOS GRANDES */
  @media screen and (max-width:992px) {
    .header{
        top:initial;
        bottom:0;
    }

    .nav{
        height: var(--header-height);
    }

    .nav__menu{
        position: fixed;
        bottom: -100%;
        left: 0;
        width: 100%;
        background-color: var(--body-color);
        padding: 2rem 1.5rem 4rem;
        box-shadow: 0 -1px 4px rgba(0,0,0, 0.15);
        border-radius: 1.5rem 1.5rem 0 0;
        transition: .3s;
    }

    /* Mostrar MENU  */

    .show-menu {
        bottom:0;
    }

    .nav__list{
        display:grid;
        grid-template-columns: repeat(3, 1fr) ;
        gap:2rem;
    }

    .nav__icon{
        font-size: 1.2rem;
    }

    .nav__close{
        position:absolute;
        right:1.3rem;
        bottom: .5rem;
        font-size: 1.5rem;
        cursor: pointer;
        color: var(--title-color);
    }

    .nav__close:hover{
        color: var(--title-color-dark);
    }

    .nav__toggle{
        font-size: 1.1rem;
        cursor: pointer;
    }

    .nav__icon,
    .nav__close,
    .nav__toggle{
        display:block;
    }

      
  }


/* PARA DISPOSTIVOS PEQUENOS */
  @media screen and (max-width:350px) {
  
  .nav__menu{
    padding:2rem 0.25rem 4rem;
  }

  .nav__list{
    column-gap: 0;
  }
}